
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.header {
	background-image: url(../img/bg-header.jpg);
	background-position: right bottom;
	height: 1000px;
}

@media (max-width: 1024px) {
	.header {
		background-position: center bottom;
		height: 100vh;
	}
}

@media (max-width: 767px) {
	.header {
		background-position: left bottom;
		height: 100vh;
	}
}

.card-footer {
	display: flex;
	padding: 0;

	a {
		flex-basis: 50%;
		text-align: center;
		padding: 16px 8px;
		color: $color-text;
		font-weight: 400;
		letter-spacing: 0.5px;
		background-color: $color-bg-lighter;
		transition: background-color 0.5s ease-out;

		&:hover {
			color: #fff;
			transition: background-color 0.5s ease-out;
		}
	}

	.btn-demo {
		border-right: 1px solid $color-divider-light;

		&:hover {
			background-color: $color-info;
		}
	}

	.btn-buy {


		&:hover {
			background-color: $color-success;
		}
	}
}